import { Box, List, ListItem, Stack, Text } from '@chakra-ui/react'
import { faSparkle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans, t } from '@lingui/macro'

import {
  MAX_CARDS_FREE,
  MAX_CARDS_PLUS,
  MAX_CARDS_PRO,
} from 'modules/ai/generator/hooks/useMaxCards'
import { MAX_TOKENS_PRO } from 'modules/ai/generator/hooks/useMaxTokens'
import { Currency, FrequencyUnit, ProductsQuery } from 'modules/api'
import {
  FREE_PROPER_NOUN,
  GAMMA_ARTIFACT_PROPER_NOUN_PLURAL,
  GAMMA_PROPER_NOUN,
  PLUS_PROPER_NOUN,
  PRO_PROPER_NOUN,
} from 'modules/i18n/properNouns'
import {
  IMAGE_GENERATE_MODELS,
  isImageModelAvailable,
  isImageModelPremium,
} from 'modules/media/apis/imageGenerate'

import {
  BillingCycleDetail,
  BillingCycleKey,
  ProductDetail,
  ProductKey,
} from '../../types'

type ProductDetails = Record<ProductKey, ProductDetail>

const AISparkle = () => {
  return (
    <Box color="trueblue.500">
      <FontAwesomeIcon icon={faSparkle} fixedWidth />
    </Box>
  )
}

const GradientText = ({ children }) => {
  return (
    <Box
      as="span"
      bg="var(--chakra-colors-gradient-blue-to-orange)"
      color="transparent"
      backgroundClip="text"
    >
      {children}
    </Box>
  )
}

const ImageModelsTooltip = ({ premium }: { premium: boolean }) => {
  const models = Object.entries(IMAGE_GENERATE_MODELS).filter(
    ([key]) => premium == isImageModelPremium(key)
  )

  return (
    <Stack spacing="0" fontSize="xs">
      <Text color="gray.400">
        <Trans>Currently:</Trans>
      </Text>
      <List>
        {models.map(([key, info]) => {
          if (!isImageModelAvailable(key)) return null
          return <ListItem key={key}>{info.label()}</ListItem>
        })}
      </List>
    </Stack>
  )
}

export const getProductDetails = (): ProductDetails => {
  return {
    free: {
      name: FREE_PROPER_NOUN,
      description: t`For casual users just getting started`,
      buttonVariant: 'plain',
      backgroundColor: 'gray.50',
      featureHeading: t`Plan includes:`,
      features: [
        {
          label: t`400 AI credits at signup`,
          icon: <FontAwesomeIcon icon={faSparkle} fixedWidth />,
        },
        { label: t`Generate up to ${MAX_CARDS_FREE} cards` },
        {
          label: t`Basic image models`,
          infoTip: <ImageModelsTooltip premium={false} />,
        },
        { label: t`Unlimited ${GAMMA_ARTIFACT_PROPER_NOUN_PLURAL} and users` },
        { label: t`PDF export (${GAMMA_PROPER_NOUN} branded)` },
        { label: t`PPT export (${GAMMA_PROPER_NOUN} branded)` },
      ],
    },
    plus: {
      name: PLUS_PROPER_NOUN,
      description: t`For personal use. Get unlimited AI and remove ${GAMMA_PROPER_NOUN} branding.`,
      buttonVariant: 'outline',
      titleColor: 'trueblue.600',
      descriptionColor: 'trueblue.700',
      backgroundColor: 'trueblue.50',
      featureHeading: t`Plan includes:`,
      features: [
        {
          label: (
            <GradientText>
              <Trans>Unlimited AI usage</Trans>
            </GradientText>
          ),
          icon: <AISparkle />,
        },

        {
          label: (
            <GradientText>
              <Trans>Generate up to {MAX_CARDS_PLUS} cards</Trans>
            </GradientText>
          ),
          icon: <AISparkle />,
        },
        {
          label: (
            <GradientText>
              <Trans>Basic image models</Trans>
            </GradientText>
          ),
          icon: <AISparkle />,
          infoTip: <ImageModelsTooltip premium={false} />,
        },
        { label: t`Remove "Made with ${GAMMA_PROPER_NOUN}" badge` },
        { label: t`PDF export` },
        { label: t`PPT export` },
        { label: t`Priority support` },
        { label: t`Everything in ${FREE_PROPER_NOUN}` },
      ],
      dontChurnMessage: t`Don't lose your AI credits.`,
    },
    pro: {
      name: PRO_PROPER_NOUN,
      description: t`For professionals. Our most advanced AI editing and sharing features.`,
      titleColor: 'orchid.600',
      descriptionColor: 'orchid.700',
      buttonVariant: 'solid',
      backgroundGradient:
        'linear-gradient(134deg, rgba(234, 231, 255, 1) 10%, rgba(181,57,224,0.15) 60%, rgba(223,122,108,0.15) 100%)',
      backgroundImage: `url(https://cdn.gamma.app/zc87vhr30n8uf3n/bf962e249e17461184fafb003ac42263/original/stars-3.png)`,
      borderColor: 'var(--chakra-colors-gradient-blue-to-orange)',
      featureHeading: t`Plan includes:`,
      features: [
        {
          label: (
            <GradientText>
              <Trans>Unlimited AI usage</Trans>
            </GradientText>
          ),
          icon: <AISparkle />,
        },
        {
          label: (
            <GradientText>
              <Trans>Generate up to {MAX_CARDS_PRO} cards</Trans>
            </GradientText>
          ),
          icon: <AISparkle />,
        },
        {
          label: (
            <GradientText>
              <Trans>Premium image models</Trans>
            </GradientText>
          ),
          icon: <AISparkle />,
          infoTip: <ImageModelsTooltip premium={true} />,
        },
        {
          label: (
            <GradientText>
              <Trans>Longer AI inputs</Trans>
            </GradientText>
          ),
          icon: <AISparkle />,
          infoTip: t`Paste in or generate from up to ${MAX_TOKENS_PRO} tokens`,
        },
        { label: t`Remove "Made with ${GAMMA_PROPER_NOUN}" badge` },
        {
          label: t`Publish to custom domains`,
          infoTip: t`Publish your ${GAMMA_PROPER_NOUN} site to a custom domain`,
        },
        { label: t`Custom fonts` },
        { label: t`Detailed analytics` },
        { label: t`Password protection` },
        { label: t`Everything in ${PLUS_PROPER_NOUN}` },
      ],
      badgeLabel: t`Popular`,
      dontChurnMessage: t`Don't lose unlimited AI.`,
    },
  }
}

export const getBillingCycleDetails = (): Record<
  BillingCycleKey,
  BillingCycleDetail
> => {
  return {
    monthly: {
      name: t`Monthly billing`,
      description: t`Billed monthly`,
    },
    yearly: {
      name: t`Annual billing`,
      description: t`When billed annually`,
    },
  }
}

/**
 * Temporarily hard-coded in products. In future, we should get these from
 * the public products API.
 */
export const PUBLIC_GAMMA_PRODUCTS: ProductsQuery['products'] = [
  {
    __typename: 'Product',
    id: 'plus-product-id',
    key: 'plus',
    prices: [
      {
        __typename: 'ProductPrice',
        id: 'plus-product-price-month',
        price: 1000,
        currency: Currency.Usd,
        frequencyUnit: FrequencyUnit.Month,
        frequencyInterval: 1,
      },
      {
        __typename: 'ProductPrice',
        id: 'plus-product-price-year',
        price: 9600,
        currency: Currency.Usd,
        frequencyUnit: FrequencyUnit.Year,
        frequencyInterval: 1,
      },
    ],
  },
  {
    __typename: 'Product',
    id: 'pro-product-id',
    key: 'pro',
    prices: [
      {
        __typename: 'ProductPrice',
        id: 'pro-product-price-month',
        price: 2000,
        currency: Currency.Usd,
        frequencyUnit: FrequencyUnit.Month,
        frequencyInterval: 1,
      },
      {
        __typename: 'ProductPrice',
        id: 'pro-product-price-year',
        price: 18000,
        currency: Currency.Usd,
        frequencyUnit: FrequencyUnit.Year,
        frequencyInterval: 1,
      },
    ],
  },
]
