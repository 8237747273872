import { GoogleTagManagerBodyCode } from './GoogleTagManagerBodyCode'
import { GoogleTagManagerHeadCode } from './GoogleTagManagerHeadCode'

export const GoogleTagManager = () => {
  return (
    <>
      <GoogleTagManagerHeadCode />
      <GoogleTagManagerBodyCode />
    </>
  )
}

export function withGoogleTagManager<T extends Record<string, any>>(
  Component: React.FC<T>
) {
  const WithGoogleTagManagerComponent = (props: T) => (
    <>
      <GoogleTagManager />
      <Component {...props} />
    </>
  )
  return WithGoogleTagManagerComponent
}
