import {
  Badge,
  Box,
  Button,
  Collapse,
  Flex,
  HStack,
  Heading,
  List,
  ListItem,
  Skeleton,
  Stack,
  Text,
  keyframes,
} from '@chakra-ui/react'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InfoTip } from '@gamma-app/ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import NextLink from 'next/link'

import { ProductPrice } from 'modules/api'
import { useLocalizedFunction } from 'modules/i18n/hooks/useLocalizedFunction'
import { formatCurrency } from 'modules/i18n/utils/currency'
import {
  getCurrencyDivisor,
  getPriceDisplayAnnually,
  getPriceDisplayMonthly,
  shouldShowCurrencyDisplayAsCode,
} from 'modules/monetization/utils'
import { useUserContext } from 'modules/user'
import { useSSRMounted } from 'utils/hooks'

import type { BillingCycleKey, ProductKey } from '../../types'
import { getBillingCycleDetails, getProductDetails } from './constants'

const Feature = ({
  feature,
}: {
  feature: {
    label: React.ReactNode
    icon?: JSX.Element
    infoTip?: React.ReactNode
  }
}) => {
  return (
    <ListItem
      display="flex"
      alignItems="flex-start"
      fontWeight="medium"
      fontSize="sm"
    >
      <HStack>
        <Box color={!feature.icon ? 'green.500' : undefined}>
          {feature.icon || <FontAwesomeIcon icon={faCheck} fixedWidth />}
        </Box>
        <Text as="span" display="inline-block">
          {feature.label}
        </Text>
        {feature.infoTip && (
          <InfoTip label={feature.infoTip} color="gray.400" />
        )}
      </HStack>
    </ListItem>
  )
}

const PriceAndBillingCycleSkeleton = ({
  displayAsTwoLines,
}: {
  displayAsTwoLines: boolean
}) => {
  return (
    <Stack spacing={2} h="80px">
      <Box minH="12">
        <Stack
          align={displayAsTwoLines ? 'flex-start' : 'flex-end'}
          direction={displayAsTwoLines ? 'column' : 'row'}
        >
          <Skeleton height="3em" width="4.5em" />
          <Stack direction="row">
            <Skeleton height=".75em" width="3em" />
            <Skeleton height=".75em" width="3em" />
          </Stack>
        </Stack>
      </Box>
      <Skeleton height="1.5em" width="10em" />
    </Stack>
  )
}
const PriceAndBillingCycle = ({
  isFree,
  annualDiscount,
  productPrice,
  selectedBillingCycleKey,
}: {
  isFree: boolean
  annualDiscount?: number
  productPrice?: ProductPrice
  selectedBillingCycleKey: BillingCycleKey
}) => {
  const { isUserLoading } = useUserContext()

  const selectedBillingCycle =
    productPrice?.frequencyUnit === 'month'
      ? getBillingCycleDetails().monthly
      : getBillingCycleDetails().yearly

  const annualPriceDisplay =
    productPrice?.frequencyUnit === 'year'
      ? getPriceDisplayAnnually(productPrice)
      : null

  const displayAsTwoLines =
    // Break the price display into two lines when:
    // The price has many digits, e.g. 10000
    getCurrencyDivisor(productPrice?.currency) < 100 ||
    // The currency is displayed as a code, e.g. TWD
    shouldShowCurrencyDisplayAsCode(productPrice?.currency)

  const hasAnnualDiscount = Boolean(annualDiscount)

  const isMounted = useSSRMounted()
  if (!isMounted || isUserLoading || (!isFree && !productPrice)) {
    return (
      <PriceAndBillingCycleSkeleton displayAsTwoLines={displayAsTwoLines} />
    )
  }

  return (
    <Box>
      <Box minH="12">
        <Stack
          align={displayAsTwoLines ? 'flex-start' : 'flex-end'}
          direction={displayAsTwoLines ? 'column' : 'row'}
        >
          <Trans comment="Example: A$16/user/month. The variable represents a currency symbol + value. If `/user/month` doesn't sound natural in your language, feel free to translate this from `per user per month` instead.">
            <Text
              fontSize="2.5rem"
              fontWeight="semibold"
              lineHeight="1"
              color="gray.800"
            >
              {isFree
                ? formatCurrency(0, { currency: productPrice?.currency })
                : getPriceDisplayMonthly(productPrice)}
            </Text>
            <Text
              color="gray.600"
              lineHeight={displayAsTwoLines ? 1 : undefined}
              fontSize="sm"
              pb={displayAsTwoLines ? '1' : undefined}
            >
              / user / month
            </Text>
          </Trans>
        </Stack>
      </Box>
      <Text
        textAlign={displayAsTwoLines ? 'left' : undefined}
        fontWeight="bold"
        color="gray.700"
      >
        {isFree ? (
          <Trans>Free plan</Trans>
        ) : annualPriceDisplay ? (
          `${selectedBillingCycle.description} (${annualPriceDisplay})`
        ) : (
          selectedBillingCycle.description
        )}
      </Text>
      <Collapse in={selectedBillingCycleKey === 'yearly'}>
        {/* Empty badge acts as a height shim for free plan */}
        {hasAnnualDiscount ? (
          <Badge
            colorScheme="green"
            fontSize="sm"
            textTransform="none"
            fontWeight="500"
            maxW="100%"
          >
            <Trans>
              Save{' '}
              {i18n.number(annualDiscount ?? 0, {
                style: 'percent',
                maximumFractionDigits: 2,
              })}{' '}
              with annual
            </Trans>
          </Badge>
        ) : (
          <Badge></Badge>
        )}
      </Collapse>
    </Box>
  )
}

const heroBeforeAnimation = keyframes({
  '0%': {
    maskPosition: '0% 50%',
  },
  '50%': {
    maskPosition: '100% 50%',
  },
  '100%': {
    maskPosition: '0% 50%',
  },
})

export const ProductDetails = ({
  productKey,
  productPrice,
  annualDiscount,
  isUpgrade = true,
  isCurrentProduct,
  upgradeDisabled,
  onClick,
  isPublic,
  selectedBillingCycleKey,
}: {
  productKey: ProductKey
  productPrice?: ProductPrice
  annualDiscount?: number
  isUpgrade?: boolean
  isCurrentProduct?: boolean
  upgradeDisabled?: boolean
  badgeLabel?: string
  onClick?: () => void
  isPublic?: boolean
  selectedBillingCycleKey: BillingCycleKey
}) => {
  const isFree = productKey === 'free'
  const hideUpgradeButton =
    !isPublic && (!onClick || (isFree && !isCurrentProduct))
  const productDetails = useLocalizedFunction(getProductDetails)
  const {
    name,
    backgroundImage,
    backgroundColor,
    backgroundGradient,
    titleColor,
    descriptionColor,
    description,
    borderColor,
    featureHeading,
    features,
    buttonVariant,
    badgeLabel,
  } = isFree ? productDetails.free : productDetails[productKey]

  return (
    <Flex
      flex={1}
      boxShadow="base"
      flexDirection="column"
      // Hack a gradient border by wrapping product in this flex (with padding + gradient bg)
      bg={borderColor ? borderColor : undefined}
      p={borderColor ? '1px' : undefined}
      m={borderColor ? undefined : '1px'}
      borderRadius="md"
      overflow="hidden"
      position="relative"
    >
      <Stack bg="white" borderRadius="md" overflow="hidden" height="100%">
        <Stack p={{ base: 3, md: 5 }} position="relative">
          {/* This box forms the background behind product title */}
          <Box
            position="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
            bg={backgroundGradient || backgroundColor || 'white'}
            _before={
              backgroundImage
                ? {
                    content: '""',
                    position: 'absolute',
                    inset: 0,
                    background: backgroundImage,
                    backgroundSize: '100%',
                    backgroundPosition: 'center center',
                    borderRadius: 'inherit',
                    animation: `${heroBeforeAnimation} 10s linear infinite`,
                    maskImage:
                      'linear-gradient(to left, rgba(0,0,0,.6), transparent, rgba(0,0,0,.6))',
                    maskRepeat: 'repeat',
                    maskSize: '160px',
                  }
                : {}
            }
          />
          <Heading
            position="relative"
            color={titleColor}
            fontFamily="p22-mackinac-pro"
            fontWeight="500"
            size={{ base: 'xl', lg: 'lg' }}
          >
            {name}
          </Heading>
          <Text
            fontSize="sm"
            position="relative"
            color={descriptionColor}
            lineHeight="1.3em"
            minHeight="2.6em"
          >
            {description}
          </Text>
          {badgeLabel && (
            <Badge
              position="absolute"
              top="0"
              left="50%"
              transform="translateX(-50%)"
              bgGradient="linear-gradient(120deg, #3300D9 0%, #9D20C9 56.82%, #DF7A6C 150%)"
              color="white"
              px="2"
              py="1"
            >
              {badgeLabel}
            </Badge>
          )}
        </Stack>

        <Stack
          p={{ base: 3, md: 5 }}
          pb={{ base: 4, md: 8 }}
          spacing="5"
          bg="white"
          h="100%"
        >
          <PriceAndBillingCycle
            productPrice={productPrice}
            annualDiscount={annualDiscount}
            isFree={isFree}
            selectedBillingCycleKey={selectedBillingCycleKey}
          />
          {hideUpgradeButton ? (
            <></>
          ) : isPublic ? (
            <Button
              variant="fancy"
              size="lg"
              width="100%"
              as={NextLink}
              href="/signup"
              textShadow="none"
            >
              <Trans>Get Started</Trans>
            </Button>
          ) : isCurrentProduct ? (
            <Button
              as={Box}
              variant="ghost"
              size="lg"
              colorScheme="gray"
              textShadow="none"
              pointerEvents="none"
              bg="gray.100"
              width="100%"
              visibility={hideUpgradeButton ? 'hidden' : 'visible'}
            >
              <Trans>Current plan</Trans>
            </Button>
          ) : (
            <Button
              variant={buttonVariant}
              size="lg"
              width="100%"
              onClick={onClick}
              visibility={hideUpgradeButton ? 'hidden' : 'visible'}
              isDisabled={upgradeDisabled}
              textShadow="none"
            >
              {isUpgrade ? (
                <Trans>Upgrade to {name}</Trans>
              ) : (
                <Trans>Downgrade to {name}</Trans>
              )}
            </Button>
          )}
          <Box zIndex="1">
            <Text fontWeight="bold" mb="4">
              {featureHeading}
            </Text>
            <List spacing="2.5">
              {features.map((feature, index) => (
                <Feature key={index} feature={feature} />
              ))}
            </List>
          </Box>
        </Stack>
      </Stack>
    </Flex>
  )
}
