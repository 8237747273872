import { useCanUseProductFeature } from 'modules/monetization/hooks/productFeatures'

export const MAX_TOKENS_FREE = 5000
export const MAX_TOKENS_PRO = 25000

export const useMaxTokens = () => {
  const hasProTokenCount = useCanUseProductFeature('ai_premium_models')
  const maxTokens = hasProTokenCount ? MAX_TOKENS_PRO : MAX_TOKENS_FREE
  return {
    maxTokens,
    canUpgrade: !hasProTokenCount,
  }
}
